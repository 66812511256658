import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import reportWebVitals from './reportWebVitals';

import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { enGB } from 'date-fns/locale';

import Portal from './containers/portal/Portal';
import Status from './containers/ui/Status';

import store from './state/store';
import RequireAuth from './components/auth/RequireAuth';

/**
 * Wrapping it in a separate component calls store() only when the component is mounted
 * This will only create the redux store if the authentication is successful,
 * and it will be removed when the user logs out.
 **/
const AuthenticatedApp = () => (
  <LocalizationProvider dateAdapter={DateAdapter} locale={enGB}>
    <Provider store={store()}>
      <Portal />
      <Status />
    </Provider>
  </LocalizationProvider>
);

ReactDOM.render(
  // <React.StrictMode>
  <RequireAuth>
    <AuthenticatedApp />
  </RequireAuth>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
