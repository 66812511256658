export function getAuthConfig() {
  if (!process.env.REACT_APP_AUTH_REGION) {
    console.error('No auth region specified');
    return;
  } else if (!process.env.REACT_APP_AUTH_USERPOOL_ID) {
    console.error('No userpool id specified');
    return;
  } else if (!process.env.REACT_APP_AUTH_USERPOOL_WEBCLIENT_ID) {
    console.error('No Webclient id specified');
    return;
  } else if (!process.env.REACT_APP_AUTH_DOMAIN) {
    console.error('No OAuth domain specified');
    return;
  } else if (!process.env.REACT_APP_AUTH_REDIRECT_URL) {
    console.error('No Redirect url specified');
    return;
  }

  return {
    Auth: {
      // Amazon Cognito Region
      region: process.env.REACT_APP_AUTH_REGION,

      // Amazon Cognito User Pool ID
      userPoolId: process.env.REACT_APP_AUTH_USERPOOL_ID,

      // Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolWebClientId: process.env.REACT_APP_AUTH_USERPOOL_WEBCLIENT_ID,

      oauth: {
        domain: process.env.REACT_APP_AUTH_DOMAIN, // Only domain name, no http(s) or trailing slash
        scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
        redirectSignIn: process.env.REACT_APP_AUTH_REDIRECT_URL, // same as set in console
        redirectSignOut: process.env.REACT_APP_AUTH_REDIRECT_URL, // same as set in console
        responseType: 'code', // use “code” if using the auth grant flow, “token” for implicit grant
      },
    },
  };
}
