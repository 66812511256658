import * as actionTypes from './actionTypes';

const authInitialState = {
  // User Details
  username: "",
  attributes: {},
};

export const reducer = (state = authInitialState, action) => {
  switch (action.type) {
    case actionTypes.USER_GET:
      const user = action.payload.user;
      return {
        ...state,
        username: user.username,
        attributes: user.attributes,
      };
    default:
      return state;
  }
};
