import TextField from '@mui/material/TextField';

import * as utils from '../../utils';

import Dropdown from './Dropdown';
import CheckboxGroup from './CheckboxGroup';
import CheckboxSingle from './CheckboxSingle';
import DatePickerInput from './DatePickerInput';
import TimePickerInput from './TimePickerInput';

const FormInputs = (props) => {
  const { columns, tables, rowData, inputValidation, data, mode, onChange } = props;

  // get actual input props from MDD field attributes and data
  const getInputProps = (attrs, index) => {
    const required = (attrs.required || attrs.PK);
    const value = ((typeof rowData[attrs.name] === 'undefined' || rowData[attrs.name] === null) ? '' : rowData[attrs.name]);
    const label = (attrs.label + (attrs.type === 'lookup' && required ? ' *' : ''));
    const disabled = (mode === 'delete' || (attrs.PK && mode !== 'create') || attrs.readOnly);
    return {
      name: attrs.name,
      id: attrs.name,
      // readabe() - date can be item name, needs formatting
      // if it's a normal input, the date will be converted by datepicker
      value: disabled ? utils.readable(value) : value,
      label: label,
      required: required,
      error: inputValidation[attrs.name]?.error,
      helperText: inputValidation[attrs.name]?.error ? inputValidation[attrs.name]?.errorMessage : attrs.hint,
      disabled: disabled,
      onChange,
    }
  }

  return (
    <div className="FormInputs">
      {columns.map((column, index) => {
        const inputProps = getInputProps(column, index);
        if (column.hidden) {

          // hidden field

          return <input type="hidden" name={inputProps.name} value={inputProps.value} key={index} />

        } else {
          let input;
          if (column.type === 'lookup') {

            // get lookup options

            let options;
            const lookup = column.lookup.values;
            if (Array.isArray(lookup)) {
              // options are preset in MDD
              options = lookup;
            } else {
              // options come from a table - find its PK field and get options from it
              const PKAttrName = tables[lookup].attributes.find((attr) => attr.PK).name;
              options = data[lookup].rows.map((row) => row[PKAttrName]);
            }
            if (column.lookup?.multiple) {

              // many-to-many lookup

              input = <CheckboxGroup options={options} {...inputProps} />
            } else {

              // regular lookup

              input = <Dropdown options={options} {...inputProps} />
            }
          } else if (column.type === 'boolean') {

            // check box

            input = <CheckboxSingle {...inputProps} />

          } else if (column.type === 'date') {

            // date

            input = <DatePickerInput {...inputProps} />

          } else if (column.type === 'time') {

            // time

            input = <TimePickerInput {...inputProps} />

          } else if (column.type === 'datetime') {

            // datetime

            input = <DatePickerInput {...inputProps} isdatetime="true" />

          } else {

            // text input

            if (column.type === 'multiline') {
              inputProps['multiline'] = true;
              inputProps['rows'] = 4;
            }
            input = <TextField variant="outlined" fullWidth {...inputProps} />
          }
          return (
            <div className="TableEdit__field" key={index}>
              {input}
            </div>
          )
        }
      })}
    </div>
  )
}

export default FormInputs;
