import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../state/ui/actions';

import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const Status = () => {
  const status = useSelector((state) => state.ui.status);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(actions.dropStatus());
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  if (status.active) {
    return (
      <div className="Status">
        <Snackbar open={status.active} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
          <Alert severity={status.severity} onClose={handleClose}>
            {status.message.split('\n').map((item, index) => <Typography key={index}>{item}</Typography>)}
          </Alert>
        </Snackbar>
      </div>
    )
  } else {
    return null;
  }
}

export default Status;
