import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckboxSingle = (props) => {
  const { name, value, label, disabled, onChange } = props;

  const handleChange = (event) => {
    const mockEvent = {
      target: {
        name,
        value: event.target.checked
      }
    }
    onChange(mockEvent);
  }

  return (
    <FormControlLabel
      control={
        <Checkbox name={name} checked={value} disabled={disabled} onChange={handleChange} />
      }
      label={label}
    />
  );
}

export default CheckboxSingle;
