import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

const Dropdown = (props) => {
  const { name, value, label, required, error, helperText, disabled, options, onChange } = props;

  return (
    <FormControl fullWidth error={error}>
      <InputLabel id={`${name}-label`}>{label}</InputLabel>
      <Select
        labelId={`${name}-label`}
        name={name}
        id={name}
        value={value}
        label={label}
        disabled={disabled}
        onChange={onChange}
      >
        {!required ? <MenuItem value="">-- no selection --</MenuItem> : null}
        {options.map((option, index) =>
          <MenuItem value={option} key={index}>{option}</MenuItem>
        )}
      </Select>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default Dropdown;
