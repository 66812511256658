export const MENU_TOGGLE = 'toggleMenu';

export const FORM_EDIT = 'editForm';
export const FORM_DROP = 'dropForm';
export const FORM_SWITCH_TAB = 'switchTab';
export const FORM_CHANGE_VALUE = 'changeFieldValue';
export const FORM_SUMBIT = 'submitForm';
export const FORM_START_CRUD = 'startCRUD';

export const FORM_DATA_RESTORE = 'restoreData';

export const FORM_LIST_EDIT = 'editListForm';
export const FORM_LIST_DROP = 'dropListForm';
export const FORM_LIST_CHANGE_VALUE = 'changeListFieldValue';
export const FORM_LIST_SUMBIT = 'submitListForm';
export const FORM_LIST_START_CRUD = 'startListCRUD';

export const STATUS_SHOW = 'showStatus';
export const STATUS_DROP = 'dropStatus';
