import { useDispatch, useStore } from 'react-redux';
import * as ui from '../../state/ui/actions';
import * as utils from '../../utils';

import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import MaterialTable from "material-table";

import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
const generateClassName = createGenerateClassName({
  productionPrefix: 'mt',
  seed: 'mt'
});

const ListGrid = ({ value, fieldAttributes, isMap }) => {
  const dispatch = useDispatch();

  const state = useStore().getState();

  const onRowClick = (event, listRowData) => {
    dispatch(ui.editListForm(listRowData, 'update'));
  };

  // when adding, get defaults from MDD
  const listRowDefaultData = {};
  fieldAttributes.forEach((attr) => {
    listRowDefaultData[attr.name] = utils.getDefaults(attr);
  });

  // only edit maps, no add or delete
  const addButton = isMap ? null :
    <div className="TableGrid__add">
      <Fab variant="extended" size="medium" sx={{paddingRight: "1.4em"}} color="primary" aria-label="add"
        onClick={() => dispatch(ui.editListForm(listRowDefaultData, 'create'))}
      >
        <AddIcon />
        Add
      </Fab>
    </div>
  ;

  let actions = [
    {
      icon: 'edit',
      iconProps: { className: 'TableGrid__actionButton' },
      tooltip: 'Edit',
      onClick: onRowClick
    }
  ];
  if (!isMap) {
    actions.push(
      {
        icon: 'delete',
        iconProps: { className: 'TableGrid__actionButton' },
        tooltip: 'Delete',
        onClick: (event, rowData) => dispatch(ui.editListForm(rowData, 'delete'))
      }
    );
  }

  const columns = utils.processColumnAttrs(fieldAttributes);

  utils.sortIt(value, fieldAttributes, state.portal.config.weekdayFieldName);

  return (
    <div className="ListGrid">
      {addButton}
      <StylesProvider generateClassName={generateClassName}>
        <MaterialTable
          options={{
            paging: false,
            actionsColumnIndex: -1,
            draggable: false,
            search: false,
            addRowPosition: 'first',
            headerStyle: {
              fontSize: "1rem",
            },
            rowStyle: {
              fontSize: ".9rem",
            }
          }}
          title={''}
          columns={columns}
          data={value}
          onRowClick={onRowClick}
          actions={actions}
          localization={{header: { actions: ''}}}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => null
          }}
        />
      </StylesProvider>
    </div>
  )
}

export default ListGrid;
