import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../state/portal/actions';

import CircularProgress from '@mui/material/CircularProgress';

import TableGrid from './TableGrid';
import TableEdit from './TableEdit';

const Table = ({ table, tables }) => {
  const ready = useSelector((state) => state.portal.loading.ready);
  const mode = useSelector((state) => state.ui.form.mode);
  const dispatch = useDispatch();

  if (! ready) {
    // init lookups loading if any
    let lookupPaths = [];
    table.attributes.forEach((attr) => {
      if (attr.type === 'lookup' && typeof attr.lookup.values === 'string') {
        const lookupTable = Object.values(tables).find((table) => table.path === attr.lookup.values);
        if (lookupTable && lookupPaths.indexOf(lookupTable.path) === -1) {
          lookupPaths.push(lookupTable.path);
        }
      }
    });

    // load everything, main table and all lookups
    const loadingPaths = [table.path, ...lookupPaths];
    dispatch(actions.initLoading(loadingPaths));

    loadingPaths.forEach((path) => {
      dispatch((dispatch) => actions.fetchDataThunk(dispatch, path));
    });

    return (
      <div className="Table--loading">
        <CircularProgress />
      </div>
    )
  }

  return (
    <div className="Table">
      {mode ?
        <TableEdit table={table} tables={tables} mode={mode} />
      :
        <TableGrid table={table} />
      }
    </div>
  )
}

export default Table;
