import { useSelector, useDispatch } from 'react-redux';
import * as ui from '../../state/ui/actions';
import * as utils from '../../utils';

import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";

import MaterialTable, { MTableToolbar } from "material-table";

import { StylesProvider, createGenerateClassName } from '@material-ui/styles';
const generateClassName = createGenerateClassName({
  productionPrefix: 'mt',
  seed: 'mt'
});

const TableGrid = ({ table }) => {
  const data = useSelector((state) => state.portal.data[table.path].rows);
  const dispatch = useDispatch();

  // when adding, get defaults from MDD
  const defaultRowData = {};
  table.attributes.forEach((attr) => {
    defaultRowData[attr.name] = utils.getDefaults(attr);
  });

  const addButton = 
    !table.editOnly ?
    <div className="TableGrid__add">
      <Fab variant="extended" color="primary" aria-label="add"
        onClick={() => dispatch(ui.editForm(table.path, defaultRowData, 'create'))}
      >
        <AddIcon />
        Add {table.labels.item}
      </Fab>
    </div>
    : null
  ;

  const onRowClick = (event, rowData) => {
    const PKFieldName = table.attributes.find((attr) => attr.PK).name;
    dispatch((dispatch) => ui.crudThunk(dispatch, table.path, rowData[PKFieldName], null, 'read'));
  }

  const actions = [
    {
      icon: 'edit',
      iconProps: { className: 'TableGrid__actionButton' },
      tooltip: 'Edit',
      onClick: onRowClick
    },
    !table.editOnly ?
    {
      icon: 'delete',
      iconProps: { className: 'TableGrid__actionButton' },
      tooltip: 'Delete',
      onClick: (event, rowData) => dispatch(ui.editForm(table.path, rowData, 'delete'))
    } : null
  ];

  const columns = utils.processColumnAttrs(table.attributes);

  utils.sortIt(data, table.attributes);

  return (
    <div className="TableGrid">
      <StylesProvider generateClassName={generateClassName}>
        {addButton}
        <MaterialTable
          options={{
            paging: false,
            actionsColumnIndex: -1,
            draggable: false,
            headerStyle: {
              fontSize: "1rem",
            },
            rowStyle: {
              fontSize: ".9rem",
            }
          }}
          title={''}
          columns={columns}
          data={data}
          onRowClick={onRowClick}
          actions={actions}
          localization={{header: { actions: ''}}}
          components={{
            Container: (props) => <Paper {...props} elevation={0} />,
            Toolbar: (props) => (
              <div className="TableGrid__toolbar">
                <MTableToolbar {...props} />
              </div>
            )
          }}
        />
      </StylesProvider>
    </div> 
  )
}

export default TableGrid;
