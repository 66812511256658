import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export const SignIn = (props) => (
  <div slot="sign-in">
    <Stack alignItems={'center'}>
      <Typography component="h1" variant="h6" gutterBottom>
        Admin Portal
      </Typography>
      <Typography variant="body1" marginBottom={2}>
        Welcome to Amazon Connect Admin Portal.
      </Typography>
      <Button variant="contained" onClick={props.onClick}>
        Sign in to continue
      </Button>
    </Stack>
  </div>
);
