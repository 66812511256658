import * as actionTypes from './actionTypes';
import * as utils from '../../utils';

import { Auth } from "aws-amplify";

import axios from "axios";

import { reloadTable } from '../portal/actions';

// all CRUD and reloads
export const crudThunk = async (dispatch, path, rowId, rowData, mode, formProps, hasLists, isList, itemLabel) => {
  const method = {
    create: 'post',
    read: 'get',
    update: 'put',
    delete: 'delete'
  }[mode];

  await axios({
    method,
    url: `${process.env.REACT_APP_API_GATEWAY_URL}/${path}` + (mode !== 'create' ? `/${encodeURIComponent(rowId)}` : ''),
    data: utils.preparePayload(utils.cleanUpRowData(rowData)),
    headers: {
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    },
  })
  .then(async (response) => {
    const data = process.env.REACT_APP_LOCAL ? response.data : response.data.data;
    if (mode === 'read') {
      dispatch(editForm(path, data, 'update'));
    } else {
      dispatch(showStatus(`${formProps.successLabel} ${itemLabel}`));

      if (mode === 'update' || mode === 'create') {
        // refresh state.form.originalData from endpoint.
        // despite we've got all the row data in place (it's in the form, rowData), we need __Version__ updated,
        // hence have to re-read the row from endpoint after every update or create
        await crudThunk(dispatch, path, rowId, null, 'read');
      }
      if (isList) {
        dispatch(dropListForm());
        // refresh state.form.originalData from endpoint
        await crudThunk(dispatch, path, rowId, null, 'read');
      } else {
        dispatch(dropForm());
        // if there are lists and the row has just been added, re-open for editing, move to the first list tab
        if (hasLists && mode === 'create') {
          dispatch(editForm(path, data, 'update'));
          dispatch(switchTab(1));
        }
        dispatch(reloadTable());
      }
    }
  })
  .catch((error) => {
    dispatch(showErrorStatus(error));
    if (isList) {
      // restore rowData from state.form.originalData
      dispatch(restoreData());
    }
  });
}

export const togglePortalMenu = () => ({
  type: actionTypes.MENU_TOGGLE
});

export const editForm = (path, rowData, mode) => ({
  type: actionTypes.FORM_EDIT,
  payload: {
    path, rowData, mode
  }
});

export const editListForm = (listRowData, mode) => ({
  type: actionTypes.FORM_LIST_EDIT,
  payload: {
    listRowData, mode
  }
});

export const switchTab = (tab) => ({
  type: actionTypes.FORM_SWITCH_TAB,
  payload: {
    tab
  }
});

export const changeFieldValue = (fieldName, newValue, tableAttrs) => ({
  type: actionTypes.FORM_CHANGE_VALUE,
  payload: {
    fieldName, newValue, tableAttrs
  }
});

export const changeListFieldValue = (fieldName, newValue, tableAttrs) => ({
  type: actionTypes.FORM_LIST_CHANGE_VALUE,
  payload: {
    fieldName, newValue, tableAttrs
  }
});

export const submitForm = (fieldAttrs) => ({
  type: actionTypes.FORM_SUMBIT,
  payload: {
    fieldAttrs
  }
});

export const listSubmitForm = (listName, fieldAttrs, listRowData, listValue) => ({
  type: actionTypes.FORM_LIST_SUMBIT,
  payload: {
    listName, fieldAttrs, listRowData, listValue
  }
});

export const startCRUD = () => ({
  type: actionTypes.FORM_START_CRUD
});

export const listStartCRUD = () => ({
  type: actionTypes.FORM_LIST_START_CRUD
});

export const restoreData = () => ({
  type: actionTypes.FORM_DATA_RESTORE
});

export const dropForm = () => ({
  type: actionTypes.FORM_DROP
});

export const dropListForm = () => ({
  type: actionTypes.FORM_LIST_DROP
});

export const showErrorStatus = (error) => ({
  type: actionTypes.STATUS_SHOW,
  payload:
    error.response ?
    {
      code: error.response.status,
      message: utils.getErrorMessage(error.response.data),
      severity: 'error',
      active: true
    }
    :
    {
      code: -1,
      message: error.message,
      severity: 'error',
      active: true
    }
});

export const showStatus = (message, severity = 'success') => ({
  type: actionTypes.STATUS_SHOW,
  payload: {
    code: 0,
    message,
    severity,
    active: true
  }
});

export const dropStatus = () => ({
  type: actionTypes.STATUS_DROP,
  payload: {
    code: 0,
    message: '',
    active: false
  }
});
