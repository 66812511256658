import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const CheckboxGroup = (props) => {
  const { name, value, label, disabled, options, onChange } = props;

  const handleChange = (event) => {
    // when many-to-many, value is an array
    const values = [...value];
    // checkbox names contain indexes, like name[0], name[1] etc. (see below), extract them
    const [name, indexDirty] = event.target.name.split('[');
    const index = parseInt(indexDirty);
    const option = options[index];
    // add/remove checked/unchecked value from the array
    if (event.target.checked) {
      values.push(option);
    } else {
      values.splice(values.indexOf(option), 1);
    }

    const mockEvent = {
      target: {
        name,
        value: values
      }
    }
    onChange(mockEvent);
  }

  return (
    <FormControl component="fieldset" variant="standard" className="CheckboxGroup">
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options.map((option, index) => {
          const checked = value.indexOf(option) !== -1;
          return (
            <FormControlLabel key={index}
              control={
                <Checkbox name={`${name}[${index}]`} checked={checked} disabled={disabled} onChange={handleChange} />
              }
              label={option}
            />
          )
        })}
      </FormGroup>
    </FormControl>
  )
}

export default CheckboxGroup;
