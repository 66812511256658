import { combineReducers } from 'redux';

import { reducer as portal } from './portal/reducer';
import { reducer as ui } from './ui/reducer';
import { reducer as auth } from './auth/reducer';

const reducer = combineReducers({
  portal,
  ui,
  auth,
});

export default reducer;
