import { useState } from 'react';
import { format, isValid } from 'date-fns';

import TextField from '@mui/material/TextField';
import TimePicker from '@mui/lab/TimePicker';

const TimePickerInput = (props) => {
  const { name, value, label, disabled, onChange } = props;

  const time = new Date();
  if (typeof value === 'string') {
    const [hours, minutes] = value.split(":");
    time.setHours(hours, minutes, 0, 0);
  } else {
    time.setHours(0, 0, 0, 0);
  }

  const [inputValue, setInputValue] = useState(time);

  const handleChange = (value) => {
    const mockEvent = {
      target: {
        name,
        value: isValid(value) ? format(value, 'HH:mm') : value,
      }
    }
    onChange(mockEvent);
    setInputValue(value)
  }

  if (disabled) {
    return <TextField variant="outlined" fullWidth {...props} />
  } else {
    return (
      <TimePicker
        label={label}
        inputFormat="HH:mm"
        value={inputValue}
        onChange={handleChange}
        renderInput={(params) => <TextField variant="outlined" fullWidth {...params} {...props} value={inputValue} />}
      />
    );
  }
}            

export default TimePickerInput;
