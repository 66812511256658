import * as actionTypes from './actionTypes';
import { Auth } from 'aws-amplify';

export const getUserInfoThunk = async (dispatch) => {
  const user = await Auth.currentAuthenticatedUser();
  dispatch(getUserInfo(user));
};

export const getUserInfo = (user) => ({
  type: actionTypes.USER_GET,
  payload: {
    user,
  },
});
