import { useState } from 'react';
import { format, isValid } from 'date-fns';

import TextField from '@mui/material/TextField';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';

const DatePickerInput = (props) => {
  const { name, value, label, disabled, onChange, isdatetime } = props;
  const inputFieldProps = { name, value, label, disabled, onChange };

  const inputFormat = 'dd/MM/yyyy' + (isdatetime ? ' HH:mm' : '');
  const outputFormat = 'yyyy-MM-dd' + (isdatetime ? ' HH:mm' : '');

  const [inputValue, setInputValue] = useState(value);

  const handleChange = (date) => {
    const value = isValid(date) ? format(date, outputFormat) : date;
    const mockEvent = {
      target: {
        name,
        value,
      },
    };
    onChange(mockEvent);
    setInputValue(date);
  };

  if (disabled) {
    return (
      <TextField id={name} variant="outlined" fullWidth {...inputFieldProps} />
    );
  } else {
    const Picker = isdatetime ? DateTimePicker : DatePicker;
    return (
      <Picker
        label={label}
        inputFormat={inputFormat}
        value={inputValue}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            variant="outlined"
            fullWidth
            {...params}
            {...inputFieldProps}
            value={inputValue}
          />
        )}
      />
    );
  }
};

export default DatePickerInput;
