import * as actionTypes from './actionTypes';

import * as utils from '../../utils';

const dataInitialState = {
  // global config
  config: {},

  // application meta descriptions
  apps: {
    list: {},     // list: { [path]: {<app meta>}, ... }
    ready: false, // loaded all at once on initial load
  },

  // current application tables' meta descriptions
  tables: {}, // { [path]: {<table meta>}, ... }

  // current application tables' rows
  data: {}, // { [path]: {rows: [] }, ... }

  // indicates data loading
  // init loading.list with an array of paths to load (the main table and all the lookups),
  // then remove them one by one when ready - empty array means all loaded (ready)
  loading: {
    list: [],
    ready: false,
  },

  // where we currently are in the portal, the path is app/table
  path: {
    app: '',
    table: '',
  },
}

export const reducer = (state = dataInitialState, action) => {
  switch (action.type) {
    case actionTypes.APPS_FETCH:
      const apps = action.payload.data.apps;
      const defaultAppPath = apps[0].path;
      const defaultTablePath = apps[0].tables[0].path;
      return {
        ...state,
        config: action.payload.data.config,
        apps: {
          list: utils.arrayToObject(apps, 'path'),
          ready: true,
        },
        tables: utils.arrayToObject(apps[0].tables, 'path'),
        path: {
          app: defaultAppPath,
          table: defaultTablePath,
        }
      }
    case actionTypes.APPS_SWITCH:
      const currentAppTables = state.apps.list[action.payload.path].tables;
      const currentTablePath = currentAppTables[0].path;
      return {
        ...state,
        tables: utils.arrayToObject(currentAppTables, 'path'),
        loading: {
          ...dataInitialState.loading
        },
        path: {
          app: action.payload.path,
          table: currentTablePath,
        }
      }
    case actionTypes.TABLES_SWITCH:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.path]: {
            rows: [],
          }
        },
        loading: {
          ...dataInitialState.loading
        },
        path: {
          ...state.path,
          table: action.payload.path,
        }
      }
    case actionTypes.DATA_INIT_LOADING:
      return {
        ...state,
        loading: {
          list: action.payload.paths,
          ready: false,
        }
      }
    case actionTypes.TABLES_RELOAD:
      return {
        ...state,
        loading: {
          list: [],
          ready: false,
        }
      }
    case actionTypes.DATA_FETCH:
      // remove the fetched table from the loading list - it's ready
      const loading = state.loading.list.filter((path) => path !== action.payload.path);
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.path]: {
            rows: action.payload.rows,
          }
        },
        loading: {
          list: loading,
          ready: !loading.length,
        }
      }
    default:
      return state;
  }
}
