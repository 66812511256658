import { useState, useEffect } from 'react';

import Amplify, { Auth } from '@aws-amplify/auth';
import { onAuthUIStateChange, AuthState } from '@aws-amplify/ui-components';
import {
  AmplifyContainer,
  AmplifyAuthenticator,
  AmplifyAuthContainer,
} from '@aws-amplify/ui-react';

import { SignIn } from './SignIn';

import { getAuthConfig } from '../../authConfig';

Amplify.configure(getAuthConfig());

const RequireAuth = (props) => {
  const [authState, setAuthState] = useState();
  const [user, setUser] = useState();

  // SSO identity provider
  // "For other identity providers this would be the name you assigned to the IdP in your user pool."
  // const provider = process.env.REACT_APP_COGNITO_IDP;

  useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData);
    });
  }, []);

  return authState === AuthState.SignedIn && user ? (
    props.children
  ) : (
    <AmplifyContainer>
      <AmplifyAuthContainer>
        <AmplifyAuthenticator>
          <SignIn onClick={async () => Auth.federatedSignIn()} />
        </AmplifyAuthenticator>
      </AmplifyAuthContainer>
    </AmplifyContainer>
  );
};

export default RequireAuth;
