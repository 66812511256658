import { useSelector, useDispatch, useStore } from 'react-redux';
import * as ui from '../../state/ui/actions';
import * as utils from '../../utils';

import TabPanel from '../../components/form/TabPanel';

import ListGrid from './ListGrid';
import ListEdit from './ListEdit';
import FormInputs from '../../components/form/FormInputs';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import CircularProgress from '@mui/material/CircularProgress';

const TableEdit = ({ table, tables, mode }) => {
  const data = useSelector((state) => state.portal.data);
  const rowData = useSelector((state) => state.ui.form.rowData);
  const tab = useSelector((state) => state.ui.form.tab);
  const listMode = useSelector((state) => state.ui.form.list.mode);
  const inputValidation = useSelector((state) => state.ui.form.inputValidation);
  const doCRUD = useSelector((state) => state.ui.form.doCRUD);
  const updating = useSelector((state) => state.ui.form.updating);
  const dispatch = useDispatch();

  const state = useStore().getState();

  const getInputProps = (attrs) => ({
    name: attrs.name,
    value: rowData[attrs.name] !== '' ? rowData[attrs.name] : utils.getDefaults(attrs),
    label: attrs.label,
  });

  // add a key field to list attributes from MDD to identify list rows
  const getListAttributes = (list) => {

    const keyAttrName = utils.CONFIG_LIST_KEY_ATTR_NAME;

    const inputProps = getInputProps(list);
    const isMap = (Array.isArray(inputProps.value) === false);

    const fieldAttributes = [
      // the key field we're adding
      {
        name: keyAttrName,
        label: '',
        // for plain lists, we don't show the key
        hidden: isMap ? false : true,
        // show it for maps, but read only
        readOnly: true,
      },
      ...list.list.attributes
    ];

    let value;
    if (isMap) {
      value = Object.keys(inputProps.value).map((key) => ({
        // for maps, key is the actual map key
        [keyAttrName]: key,
        ...inputProps.value[key],
      }));
    } else {
      value = inputProps.value.map((row, index) => ({
        // for plain lists, it's row index
        [keyAttrName]: index,
        ...row
      }));
    }

    return { name: list.name, value, fieldAttributes, keyAttrName, isMap };
  }

  const PKFieldName = table.attributes.find((attr) => attr.PK).name;

  const columns = table.attributes.filter((attr) => attr.type !== 'list');
  const lists = table.attributes.filter((attr) => attr.type === 'list');
  const hasLists = lists.length;
  const isList = false;

  const formProps = utils.getFormProps(mode);

  // Edit conflict control on back end:
  // every table has a special __Version__ field we put unchanged into every request payload
  columns.push({
    "name": state.portal.config.versionFieldName,
    "hidden": true,
  });

  if (doCRUD) {
    const formState = state.ui.form;
    if (!utils.checkLists(formState.originalData, formState.rowData, table.attributes)) {
      return <Typography color='error'>List integrity check failed</Typography>
    }

    // shows the spinner and drops doCRUD
    dispatch(ui.startCRUD());

    dispatch((dispatch) => ui.crudThunk(dispatch, table.path, rowData[PKFieldName], rowData, mode, formProps, hasLists, isList, table.labels.item));
  }

  return (
    <div className="TableEdit">
      <div className="TableEdit__form">
        <div className="TableEdit__header">
          <IconButton
              size="large"
              edge="start"
              aria-label="back"
              onClick={() => dispatch(ui.dropForm())}
              color="inherit"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {mode === 'create' ?
              // Adding Message
              `${formProps.screenLabel} ${table.labels.item}`
            :
              // Messages — Editing MESSAGE_CLOSED
              // readabe() - date can be item name, needs formatting
              `${table.labels.table} — ${formProps.screenLabel} ${utils.readable(rowData[PKFieldName])}`
            }
          </Typography>
        </div>
        {mode === 'delete'?
          <div className="TableEdit__confirm">
            <Typography color='error'>
              {`Are you sure you want to delete this ${table.labels.item}?`}
            </Typography>
          </div>
        : null}
        {hasLists && mode !== 'delete'?
          <div className="TableEdit__tabs">
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tab}>
                <Tab label="General" onClick={() => dispatch(ui.switchTab(0))} />
                {lists.map((list, index) =>
                  <Tab label={list.label} key={index} onClick={() => dispatch(ui.switchTab(index+1))} disabled={mode === 'create'} />
                )}
              </Tabs>
            </Box>
          </div>
        : null}
        <form autoComplete="off">
          <TabPanel value={tab} index={0}>
            <FormInputs columns={columns} rowData={rowData} inputValidation={inputValidation} tables={tables} data={data} mode={mode}
              onChange={(event) => {
                dispatch(ui.changeFieldValue(event.target.name, event.target.value, table.attributes));
              }}
            />
            <div className="TableEdit__buttons">
              <Button variant="contained" color={formProps.buttonColor}
                onClick={() => {
                  dispatch(ui.submitForm(table.attributes));
                }}
                disabled={updating}
                startIcon={updating ? <CircularProgress size="1rem" /> : null}>
                {formProps.buttonLabel}
              </Button>
              <Button variant="outlined" disabled={updating} onClick={() => dispatch(ui.dropForm())}>Cancel</Button>
            </div>
          </TabPanel>
          {hasLists?
            lists.map((list, index) => {
              const listAttributes = getListAttributes(list);
              return (
                <TabPanel key={index} value={tab} index={index+1}>
                  {listMode?
                    <ListEdit {...listAttributes} table={table} tables={tables} PKFieldName={PKFieldName} data={data} rowData={rowData} mode={listMode}
                      onChange={(event) =>
                        dispatch(ui.changeListFieldValue(event.target.name, event.target.value, listAttributes.fieldAttributes))
                      }
                    />
                  :
                    <ListGrid {...listAttributes} />
                  }
                </TabPanel>
              )
            })
          : null}
        </form>
      </div>
    </div>
  )
}

export default TableEdit;
