import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../state/portal/actions';
import * as ui from '../../state/ui/actions';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import DateRangeIcon from '@mui/icons-material/DateRange';
import PhoneIcon from '@mui/icons-material/Phone';
import SettingsIcon from '@mui/icons-material/Settings';

const miuIcons = {
  DateRangeIcon: <DateRangeIcon />,
  PhoneIcon: <PhoneIcon />,
  SettingsIcon: <SettingsIcon />,
}

const PortalMenu = ({ apps, currentPath }) => {
  const isOpen = useSelector((state) => state.ui.menu.open);
  const dispatch = useDispatch();

  if (!isOpen) {
    return null;
  }

  const itemClass = 'PortalMenu__item';
  const appsList = Object.values(apps.list);
  return (
    <nav className="PortalMenu">
      <List>
        {appsList.length > 1 ?
          appsList.map((app, index) =>
            <ListItem key={index} disablePadding className={itemClass + (app.path === currentPath ? ` ${itemClass}--current` : '')}>
              <ListItemButton className="PortalMenu__button" onClick={() => {
                  dispatch(actions.switchApps(app.path));
                  dispatch(ui.togglePortalMenu());
                  dispatch(ui.dropForm());
              }}>
                <ListItemIcon sx={{minWidth: 'auto', marginRight: '.5rem' }}>
                  {miuIcons[app.muiIcon]}
                </ListItemIcon>
                <ListItemText primary={app.name} />
              </ListItemButton>
            </ListItem>
          )
        : null }
      </List>
    </nav>
  );
}

export default PortalMenu;
