import { useDispatch } from 'react-redux';

import * as actions from '../../state/ui/actions';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import AccountMenu from '../../components/auth/AccountMenu';

const PortalHeader = ({ app }) => {
  const dispatch = useDispatch();

  return (
    <div className="PortalHeader">
      <AppBar position="static" elevation={0}>
        <div className="PortalHeader__bar">
          <div className="g-container">
            <Toolbar variant="dense">
              <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => dispatch(actions.togglePortalMenu())}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                {app.name}
              </Typography>
              <AccountMenu />
            </Toolbar>
          </div>
        </div>
      </AppBar>
    </div>
  );
};

export default PortalHeader;
