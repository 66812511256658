import { format } from 'date-fns';

// a name for internal field we add to list attributes from MDD to identify list rows
export const CONFIG_LIST_KEY_ATTR_NAME = '__ListKey__';

// converts array [{key, name, ...}, ...] into object {[key]: [{key, name, ...}, ...]}
export const arrayToObject = (array, keyField) => {
  return array.reduce((obj, item) => {
    obj[item[keyField]] = item;
    return obj;
  }, {});
}

// converts MDD attributes into MaterialTable columns
export const processColumnAttrs = (attrs) => {
  return attrs.map((attr, index) => {
    // if it's a multiple value lookup, handle the array of values
    const renderLookup = {};
    if (attr.type === 'lookup') {
      renderLookup.render = (rowData) => {
        const value = rowData[attr.name];
        if (Array.isArray(value)) {
          return value.join(', ');
        }
        return value;
      }
    }
    if (attr.type === 'date' || attr.type === 'datetime') {
      renderLookup.render = (rowData) => {
        const value = rowData[attr.name];
        return readable(value, attr.type === 'datetime');
      }
    }
    return {
      field: attr.name,
      title: attr.label,
      type: ['boolean', 'date', 'datetime', 'time', 'currency'].indexOf(attr.type) !== -1 ? attr.type : (attr.type === 'number' ? 'numeric' : null),
      hidden: attr.hidden || attr.hiddenInGrid || attr.type === 'list',
      customSort: (a, b) =>
        typeof a[attr.name] === 'string' ?
          a[attr.name].localeCompare(b[attr.name])
        :
          (a[attr.name] > b[attr.name] ? 1 : -1)
      ,
      ...renderLookup
    }
  });
}

// when no default value set in MDD for an attribute, we may still need one, depending on its type
export const getDefaults = (attr) => {
  if (typeof  attr.default !== 'undefined') {
    return attr.default;
  }
  if (attr.type === 'boolean') {
    return false;
  }
  if (attr.type === 'list') {
    return [];
  }
  if (attr.type === 'lookup' && attr.lookup?.multiple) {
    return [];
  }
  return '';
}

// form props for different form modes
export const getFormProps = (mode) => {
  if (mode === 'create') {
    return {
      screenLabel: 'Adding',
      buttonLabel: 'Add',
      successLabel: 'Added',
    }
  } else if (mode === 'update') {
    return {
      screenLabel: 'Editing',
      buttonLabel: 'Update',
      successLabel: 'Updated',
    }
  } else if (mode === 'delete') {
    return {
      screenLabel: 'Deleting',
      buttonLabel: 'Delete',
      buttonColor: 'error',
      successLabel: 'Deleted',
    }
  }
}

// remove MaterialTable (tableData) and other stuff
export const cleanUpRowData = (rowData, attrNames = []) => {
  const data = {...rowData};
  attrNames.push('tableData');
  attrNames.forEach((attrName) => delete data[attrName]);
  return data;
}

// prepare JSON for CRUD payload
// - convert empty strings to nulls // that probably would be nice but, as per agreement with backend, we don't do that - commented out for now
// - trim values
export const preparePayload = (obj) => {
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      if (typeof value === 'string' && value !== '') {
        const valueTrim = value.trim();
        // if (valueTrim === '') {
        //   return null;
        // }
        return valueTrim;
      }
      return value;
    })
  );
}

// convert database date to human readable one
// some other conversions can be in here if needed
// TODO convert based on user locale
// TODO datetime
export const readable = (dateStr, isDateTime) => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(dateStr)) {
    const [y, m, d] = dateStr.split('-');
    const date = new Date(y, m-1, d);
    return format(date, 'dd/MM/yyyy');
  }
  return dateStr;
}

// TODO! better deep copy
export const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
}

// scan an object and collect all values
export const getValuesFromObject = (obj) => {
  const res = [];
  const scanLevel = (obj) => {
    Object.values(obj).forEach((value) => {
      if (value !== null && typeof value === 'object') {
        scanLevel(value);
      } else if (value !== null && value !== '') {
        res.push(value);
      }
    })
  }
  scanLevel(obj);
  // filter uniquie
  return res.filter((value, index, arr) => arr.indexOf(value) === index).join('. ');
}

// translate response.data into a sensible error message
export const getErrorMessage = (responseData) => {
  let message = responseData.message;
  if (responseData.errors) {
    message += `\n${getValuesFromObject(responseData.errors)}.`;
  }
  if (responseData.records) {
    message += `\nThis record is in use by some other record(s), ie.`;
    let i = 0;
    for (const table in responseData.records) {
      for (let j = 0; j < responseData.records[table].length; j++) {
        const pk = responseData.records[table][j];
        message += `\n${table}[${pk}]`;
        // trim number of errors to 2
        if (++i === 2) {
          i = -1;
          break;
        }
      }
      if (i < 0) break;
    }
  }

  return message;
}

// Before submit, check if the lists are not going to get corrupted. For peace of mind.
export const checkLists = (originalData, newData, fieldAttrs) => {
  const listsOK = fieldAttrs.filter((attrs) => attrs.type === 'list').map((field) => {
    const originalList = originalData[field.name];
    const updatedList = newData[field.name];

    // typeof null is 'object' so these first
    if (originalList === null || updatedList === null) {
      return false;
    // the new and original lists should be both either arrays or maps (objects)
    } else if (!(
        (Array.isArray(originalList) && Array.isArray(updatedList)) ||
        (typeof originalList === 'object' && typeof updatedList === 'object')
      )) {
      return false;
    // no bulk actions allowed
    } else if (Math.abs(Object.keys(originalList).length - Object.keys(updatedList).length) > 1) {
      return false;
    }
    return true;
  });

  return listsOK.indexOf(false) === -1;
}

// sorts the original rows array
// NB intentionally not a pure function
export const sortIt = (rows, attributes, alwaysSortByFieldName) => {
  // always sort by alwaysSortByFieldName if it presents in the data
  // weekdays, for instance
  const alwaysSortBy = (rows.length && typeof rows[0][alwaysSortByFieldName] !== 'undefined');

  let sortBy = attributes.find((attrs) => attrs.defaultSort === true);

  if (!sortBy && !alwaysSortBy) {
    // sort by the very first column if thers's no 'defaultSort' one
    sortBy = attributes.find((attrs) => !attrs.hidden && !attrs.hiddenInGrid);
  }

  if (sortBy) {
    if (sortBy.type === 'number') {
      rows.sort((a, b) => (parseInt(a[sortBy.name]) > parseInt(b[sortBy.name])) ? 1 : -1);
    } else {
      rows.sort((a, b) => a[sortBy.name].localeCompare(b[sortBy.name]));
    }
  } else if (alwaysSortBy) {
    // this field is numeric, only mdd.json->config.weekdayFieldName for now
    rows.sort((a, b) => (parseInt(a[alwaysSortByFieldName]) > parseInt(b[alwaysSortByFieldName])) ? 1 : -1);
  }
}
