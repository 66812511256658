import * as actionTypes from './actionTypes';
import axios from "axios";

import { Auth } from "aws-amplify";

import { showErrorStatus } from '../ui/actions';

// thunks are NOT async here, components check ready flag in the state
export const fetchMDDThunk = (dispatch) => {
  axios.get(process.env.REACT_APP_MDD_ENDPOINT_URL)
  .then((response) => {
    dispatch(fetchMDD(response));
  })
  .catch((error) => {
    dispatch(showErrorStatus(error));
  });
}

// ..so we can fetch data in parallel then Table component knows when it's all ready (see reducer, DATA_FETCH)
export const fetchDataThunk = async (dispatch, path) => {
  axios.get(`${process.env.REACT_APP_API_GATEWAY_URL}/${path}`, {
    headers: {
      Authorization: `${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    }
  })
  .then((response) => {
    dispatch(fetchData(response.data.data, path));
  })
  .catch((error) => {
    dispatch(showErrorStatus(error));
  });
}

export const fetchMDD = (response) => ({
  type: actionTypes.APPS_FETCH,
  payload: {
    data: response.data
  }
});

export const switchApps = (path) => ({
  type: actionTypes.APPS_SWITCH,
  payload: {
    path
  }
});

export const switchTables = (path) => ({
  type: actionTypes.TABLES_SWITCH,
  payload: {
    path
  }
});

export const reloadTable = () => ({
  type: actionTypes.TABLES_RELOAD,
});

export const initLoading = (paths) => ({
  type: actionTypes.DATA_INIT_LOADING,
  payload: {
    paths
  }
});

export const fetchData = (data, path) => ({
  type: actionTypes.DATA_FETCH,
  payload: {
    path,
    rows: data
  }
});
