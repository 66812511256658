import { useDispatch } from 'react-redux';
import * as actions from '../../state/portal/actions';
import * as ui from '../../state/ui/actions';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const AppMenu = ({ tables, currentPath }) => {
  const dispatch = useDispatch();

  const tablesInMenu = Object.values(tables).filter((table) => !table.parents?.length);
  const itemClass = 'AppMenu__item';
  return (
    <nav className="AppMenu">
      <List>
        {tablesInMenu.map((table, index) =>
          <ListItem key={index} disablePadding className={itemClass + (table.path === currentPath ? ` ${itemClass}--current` : '')}>
            <ListItemButton className="AppMenu__button" onClick={() => {
              dispatch(actions.switchTables(table.path));
              dispatch(ui.dropForm());
            }}>
              <ListItemText primary={table.labels.table} />
            </ListItemButton>
          </ListItem>
        )}
      </List>
    </nav>
  )
}

export default AppMenu;
