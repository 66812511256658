import * as actionTypes from './actionTypes';
import * as utils from '../../utils';
import * as validator from './validator';

const uiInitialState = {
  // hamburger menu (apps)
  menu: {
    open: false,
  },

  // form state (TableEdit)
  form: {
    mode: '', // add|edit|delete
    path: '', // current table
    id: '',   // current row id (PK)
    tab: 0,   // current list tab (0 for general)
    originalData: {}, // untouched row data from endpoint
    rowData: {},      // row data in the form
    touched: false,   // true if value of any input has been changed

    // shows input errors on the form
    inputValidation: {}, // { [name]: { error: false, errorMessage: '' } }

    // starts a crud op on submit
    doCRUD: false,
    // indicates crud op in progress
    updating: false,

    // lists have their own form component (ListEdit)
    list: {
      mode: '',
      rowData: {},
      touched: false,
      inputValidation: {},
      doCRUD: false,
      updating: false,
    }
  },

  // global portal status (message, warning, error)
  status: {
    code: 0,
    severity: '', // error|warning|info|success
    message: '',
    active: false,
  }
}

export const reducer = (state = uiInitialState, action) => {
  switch (action.type) {
    case actionTypes.MENU_TOGGLE:
      return {
        ...state,
        menu: {
          open: !state.menu.open,
        },
      }
    case actionTypes.FORM_EDIT:
      return {
        ...state,
        form: {
          ...state.form,
          mode: action.payload.mode,
          path: action.payload.path,
          // deep copy because of lists which are nested ojects
          originalData: utils.deepCopy(action.payload.rowData),
          rowData: utils.deepCopy(action.payload.rowData),
          inputValidation: {},
          updating: false,
          list: {
            ...state.form.list,
            updating: false,
          },
        },
      }
    case actionTypes.FORM_LIST_EDIT:
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            ...state.form.list,
            mode: action.payload.mode,
            // no nested lists expected in lists, so a shallow copy is enough
            originalData: {...action.payload.listRowData},
            rowData: {...action.payload.listRowData},
            inputValidation: {},
          }
        },
      }
    case actionTypes.FORM_SWITCH_TAB:
      // Don't let switch tabs if any changes has been made in the main form,
      // otherwise unsaved changes may be saved unintentionally when editing and saving the lists under the tabs.
      // Or lost, if we choose just to drop them, hence asking.
      if (state.form.touched || state.form.list.touched) {
        return {
          ...state,
          status: {
            code: -1,
            severity: 'info',
            message: "Save or cancel changes before switching tabs",
            active: true,
          }
        }
      } else {
        return {
          ...state,
          form: {
            ...state.form,
            tab: action.payload.tab,
            list: {
              ...state.form.list,
              mode: '',
            },
          },
        }
      }
    case actionTypes.FORM_CHANGE_VALUE:
      return {
        ...state,
        form: {
          ...state.form,
          rowData: {
            ...state.form.rowData,
            [action.payload.fieldName]: action.payload.newValue,
          },
          inputValidation: {
            ...state.form.inputValidation,
            [action.payload.fieldName]: validator.validateInput(action.payload.fieldName, action.payload.newValue, action.payload.tableAttrs),
          },
          touched: true,
        },
      }
    case actionTypes.FORM_LIST_CHANGE_VALUE:
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            ...state.form.list,
            rowData: {
              ...state.form.list.rowData,
              [action.payload.fieldName]: action.payload.newValue,
            },
            inputValidation: {
              ...state.form.list.inputValidation,
              [action.payload.fieldName]: validator.validateInput(action.payload.fieldName, action.payload.newValue, action.payload.tableAttrs),
            },
            touched: true,
          },
        },
      }
    case actionTypes.FORM_SUMBIT:
      let inputsValidated = {};
      let allValid = true;
      if (state.form.mode !== 'delete') {
        inputsValidated = validator.validateAll(state.form.rowData, action.payload.fieldAttrs);
        allValid = Object.values(inputsValidated).every((input) => input.error === false);
      }

      return {
        ...state,
        form: {
          ...state.form,
          inputValidation: inputsValidated,
          doCRUD: allValid,
          touched: false,
        },
        status:
          allValid ?
            {...state.status}
          : {
              code: -1,
              severity: 'info',
              message: 'Some fields require attention',
              active: true,
            }
      }
    case actionTypes.FORM_LIST_SUMBIT:
      const mainRowData = {...state.form.rowData};

      const keyAttrName = utils.CONFIG_LIST_KEY_ATTR_NAME;
      const listKey = state.form.list.rowData[keyAttrName];

      const listName = action.payload.listName;
      const listRowData = utils.cleanUpRowData(action.payload.listRowData, [keyAttrName]);

      let listInputsValidated = {};
      let listAllValid = true;
      if (state.form.mode !== 'delete') {
        listInputsValidated = validator.validateAll(state.form.list.rowData, action.payload.fieldAttrs);
        listAllValid = Object.values(listInputsValidated).every((input) => input.error === false);
      }

      // modify list only when all valid
      if (listAllValid) {
        if (state.form.list.mode === 'delete') {
          // delete then remove keyAttrName field from entire list
          mainRowData[listName] = action.payload.listValue
            .filter((item) => item[keyAttrName] !== listKey)
            .map((item) => utils.cleanUpRowData(item, [keyAttrName]));
        } else if (state.form.list.mode === 'create') {
          mainRowData[listName].push(listRowData);
        } else {
          mainRowData[listName][listKey] = listRowData;
        }
      }

      return {
        ...state,
        form: {
          ...state.form,
          rowData: mainRowData,
          list: {
            ...state.form.list,
            inputValidation: listInputsValidated,
            doCRUD: listAllValid,
            touched: false,
          },
        },
        status:
          listAllValid ?
            {...state.status}
          : {
              code: -1,
              severity: 'info',
              message: 'Some fields require attention',
              active: true,
            }
      }
    case actionTypes.FORM_START_CRUD:
      return {
        ...state,
        form: {
          ...state.form,
          updating: true,
          doCRUD: false,
        }
      }
    case actionTypes.FORM_LIST_START_CRUD:
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            ...state.form.list,
            updating: true,
            doCRUD: false,
          }
        }
      }
    case actionTypes.FORM_DATA_RESTORE:
      return {
        ...state,
        form: {
          ...state.form,
          rowData: utils.deepCopy(state.form.originalData),
        }
      }
    case actionTypes.FORM_DROP:
      return {
        ...state,
        form: {
          ...uiInitialState.form,
        }
      }
    case actionTypes.FORM_LIST_DROP:
      return {
        ...state,
        form: {
          ...state.form,
          list: {
            ...uiInitialState.form.list,
          }
        }
      }
    case actionTypes.STATUS_SHOW:
    case actionTypes.STATUS_DROP:
      return {
        ...state,
        form: {
          ...state.form,
          updating: false,
          list: {
            ...state.form.list,
            updating: false,
          }
        },
        status: action.payload,
      }
    default:
      return state;
  }
}
