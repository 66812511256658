import { parse, isValid, getYear } from 'date-fns';

// TODO! locales and all

export const validateInput = (fieldName, value, tableAttrs) => {
  const attrs = tableAttrs.find((attrs) => attrs.name === fieldName);
  // the field is in the data but there's no input for it, ignore
  if (!attrs) {
    return { error: false };
  }

  // required
  const required = attrs.required || attrs.PK;
  // 'undefined' - no data for the field, ie. just added in MDD
  if (required && (typeof value === 'undefined' || value === null || (typeof value === 'string' && value.trim() === ''))) {
    return {
      error: true,
      errorMessage: "Value required",
    }
  }

  // no validation, quit
  if (!attrs.type && !attrs.validate) {
    return { error: false };
  }

  // first syntax
  const rules = {
    number: {
      pattern: /^\d+$/,
      errorMessage: "Value should be a number",
    },
    date: {
      // we don't test against date patterns, check date values instead
      //pattern: /^\d{2}\/\d{2}\/\d{4}$/,
      errorMessage: "Value should be a date in DD/MM/YYYY format",
    },
    time: {
      //pattern: /^\d{2}:\d{2}$/,
      errorMessage: "Invalid time",
    },
    datetime: {
      //pattern: /^\d{2}\/\d{2}\/\d{4}\s\d{2}:\d{2}$/,
      errorMessage: "Value should be datetime in DD/MM/YYYY HH:MM format",
    },
  }

  // attrs.validate overrides default rules
  const rule = attrs.validate ? attrs.validate : rules[attrs.type];

  if (rule && rule.pattern && value !== null && typeof value === 'string' && value.trim() !== '') {
    let regex = rule.pattern;
    if (!(regex instanceof RegExp)) {
      regex = new RegExp(regex);
    }
    if (!regex.test(value.trim())) {
      return {
        error: true,
        errorMessage: rule.errorMessage,
      };
    }
  }

  // then values

  // date
  if (attrs.type === 'date') {
    const parsedDate = parse(value, 'yyyy-MM-dd', new Date());
    const year = getYear(parsedDate);
    if (!isValid(parsedDate) || isNaN(year) || year < 1000) {
      return {
        error: true,
        errorMessage: rules.date.errorMessage,
      };
    }
    return { error: false };
  }

  // datetime
  if (attrs.type === 'datetime') {
    const parsedDate = parse(value, 'yyyy-MM-dd HH:mm', new Date());
    const year = getYear(parsedDate);
    if (!isValid(parsedDate) || isNaN(year) || year < 1000) {
      return {
        error: true,
        errorMessage: rules.datetime.errorMessage,
      };
    }
    return { error: false };
  }

  // time
  // TimePicker yields null when time is empty, "Invalid date" Date instance when invalid, string when ok
  if (attrs.type === 'time') {
    if (value === null) {
      return { error: false };
    }
    if (typeof value !== 'string') {
      return {
        error: true,
        errorMessage: rules.time.errorMessage,
      };
    }
    return { error: false };
  }

  return { error: false };
}

export const validateAll = (fields, fieldAttrs) => {
  const inputs = {};
  fieldAttrs.forEach((field) => {
    const fieldName = field.name;
    const validation = validateInput(fieldName, fields[fieldName], fieldAttrs);
    inputs[fieldName] = validation;
  });

  return inputs;
}
