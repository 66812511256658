import { useSelector, useDispatch, useStore } from 'react-redux';
import * as ui from '../../state/ui/actions';
import * as utils from '../../utils';

import FormInputs from '../../components/form/FormInputs';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const ListEdit = ({ name, value, fieldAttributes, table, tables, PKFieldName, data, rowData, mode, onChange }) => {
  const listRowData = useSelector((state) => state.ui.form.list.rowData);
  const listInputValidation = useSelector((state) => state.ui.form.list.inputValidation);
  const listDoCRUD = useSelector((state) => state.ui.form.list.doCRUD);
  const listUpdating = useSelector((state) => state.ui.form.list.updating);
  const dispatch = useDispatch();

  const state = useStore().getState();

  const formProps = utils.getFormProps(mode);

  if (listDoCRUD) {
    const formState = state.ui.form;
    if (!utils.checkLists(formState.originalData, formState.rowData, table.attributes)) {
      return <Typography color='error'>List integrity check failed</Typography>
    }

    // shows the spinner and drops doCRUD
    dispatch(ui.listStartCRUD());

    const hasLists = false;
    const isList = true;
    dispatch((dispatch) => ui.crudThunk(dispatch, table.path, rowData[PKFieldName], rowData, 'update', utils.getFormProps('update'), hasLists, isList, table.labels.item));
  }

  return (
    <div className="TableEdit">
      {mode === 'delete'?
        <div className="TableEdit__confirm">
          <Typography color='error'>
            Are you sure you want to delete this entry?
          </Typography>
        </div>
      : null}
      <FormInputs columns={fieldAttributes} rowData={listRowData} inputValidation={listInputValidation} tables={tables} data={data} mode={mode} onChange={onChange} />
      <div className="TableEdit__buttons">
        <Button variant="contained" color={formProps.buttonColor}
          onClick={() => {

            // will update the entire table row

            dispatch(ui.listSubmitForm(name, fieldAttributes, listRowData, value));

          }}
          disabled={listUpdating}
          startIcon={listUpdating ? <CircularProgress size="1rem" /> : null}>
          {formProps.buttonLabel}
        </Button>
        <Button variant="outlined" disabled={listUpdating} onClick={() => dispatch(ui.dropListForm())}>Cancel</Button>
      </div>
    </div>
  )
}

export default ListEdit;
