import { useSelector } from 'react-redux';

import AppMenu from './AppMenu';
import Table from './Table';

const App = () => {
  const tables = useSelector((state) => state.portal.tables);
  const path = useSelector((state) => state.portal.path.table);

  const currentTable = tables[path];

  return (
    <div className="App">
      <div className="App__cols">
        <AppMenu tables={tables} currentPath={path} />
        <Table table={currentTable} tables={tables} />
      </div>
    </div>
  )
}

export default App;
