import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as actions from '../../state/auth/actions';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LogoutIcon from '@mui/icons-material/Logout';

import { Auth } from 'aws-amplify';

const AccountMenu = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getUserInfoThunk);
  }, [dispatch]);

  const userName = useSelector((state) => state.auth.username);

  const [accountMenuElement, setAccountMenuElement] = useState(null);
  const isAccountMenuOpen = Boolean(accountMenuElement);

  const onAccountClick = (event) => {
    setAccountMenuElement(event.currentTarget);
  };

  const onAccountClose = () => {
    setAccountMenuElement(null);
  };

  return (
    <>
      <Button
        size="large"
        aria-label="User Account"
        color="inherit"
        onClick={onAccountClick}
        startIcon={<AccountCircleIcon />}
        sx={{ textTransform: 'none' }}
      >
        {userName}
      </Button>
      <Menu
        anchorEl={accountMenuElement}
        open={isAccountMenuOpen}
        onClose={onAccountClose}
        onClick={onAccountClose}
      >
        <MenuItem onClick={() => Auth.signOut()}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          Sign Out
        </MenuItem>
      </Menu>
    </>
  );
};

export default AccountMenu;
