import { useSelector, useDispatch } from 'react-redux';
import * as actions from '../../state/portal/actions';
import '../../portal.scss';

import Typography from '@mui/material/Typography';

import PortalHeader from './PortalHeader';
import PortalMenu from './PortalMenu';
import App from './App';

const Portal = () => {
  const apps = useSelector((state) => state.portal.apps);
  const path = useSelector((state) => state.portal.path.app);
  const dispatch = useDispatch();

  if (! apps.ready) {
    dispatch(actions.fetchMDDThunk);
    return (
      <div className="Portal--loading">
        <Typography component="div">
          Connecting Admin Portal...
        </Typography>
      </div>
    )
  }

  const currentApp = apps.list[path];
  return (
    <div className="Portal">
      <PortalHeader app={currentApp} />
      <div className="Portal__body">
        <div className="g-container">
          <PortalMenu apps={apps} currentPath={path} />
          <App />
        </div>
      </div>
    </div>
  )
}

export default Portal;
